<template>
  <div class="financial">
    <!-- definir consultor -->
    <b-modal
      ref="new-manager-modal"
      hide-footer
      size="md"
      title="Definir consultor"
    >
      <b-form-select
        id="manager_id"
        v-model="newManagerId"
        :options="newManagers"
      />

      <div class="d-flex justify-content-around mt-2">
        <b-button
          :disabled="isSubmitingNewManager"
          variant="primary"
          @click="setNewManager"
        >
          Definir
          <b-spinner
            v-if="isSubmitingNewManager"
            small
          />
        </b-button>

        <b-button
          :disabled="isSubmitingNewManager"
          @click="$refs['new-manager-modal'].hide()"
        >
          Canclar
        </b-button>
      </div>
    </b-modal>

    <!-- modal -->
    <b-modal
      ref="payment-modal"
      hide-footer
      size="lg"
      title="Solicitar pagamento de Assinatura"
    >
      <new-payment
        v-if="filterByUser !== null"
        ref="payment-component"
        :user-id="filterByUser"
        @payment-created="getFinancials()"
        @close="closeFinancials()"
      />
    </b-modal>

    <b-modal
      ref="new-product-modal"
      hide-footer
      size="lg"
      title="Solicitar pagamento de Produto"
    >
      <new-product
        v-if="filterByUser !== null"
        ref="new-product-component"
        :user-id="filterByUser"
        @payment-created="getFinancials()"
        @close="closeNewProduct()"
      />
    </b-modal>

    <b-row>
      <b-col cols="12">
        <b-card-actions
          v-if="filterByUser === null"
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="ID do usuário"
                label-for="filter_user_id"
              >
                <b-form-input
                  id="filter_user_id"
                  v-model="filtersValues.user_id"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Forma de pagamento"
                label-for="filter_type"
              >
                <b-form-select
                  id="filter_type"
                  v-model="filtersValues.payment_type"
                  :options="filtersOptions.payment_type"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Status"
                label-for="filter_status"
              >
                <b-form-select
                  id="filter_status"
                  v-model="filtersValues.status"
                  :options="filtersOptions.status"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Recorrente"
                label-for="filter_recurrent"
              >
                <b-form-select
                  id="filter_status"
                  v-model="filtersValues.recurrent"
                  :options="filtersOptions.recurrent"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="$can('list_all_managers' ,'Financial')"
              cols="3"
            >
              <b-form-group
                label="Gerente"
                label-for="account_manager"
              >
                <b-form-select
                  id="account_manager"
                  v-model="filtersValues.account_manager"
                  :options="filtersOptions.account_manager"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              <span class="label-date text-right">Criação</span>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="De"
                label-for="created_at_from"
              >
                <b-input-group>
                  <cleave
                    id="created_at_from"
                    v-model="runtimeFilters.created_at_from"
                    type="text"
                    class="form-control"
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                    :raw="false"
                    show-decade-nav
                    :options="maskDate"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="created_at_from_dp"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                      locale="pt-BR"
                      aria-controls="example-input"
                      @context="(ctx) => { runtimeFilters.created_at_from = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                    />
                  </b-input-group-append>

                  <b-input-group-append v-if="runtimeFilters.created_at_from.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="runtimeFilters.created_at_from = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Até"
                label-for="created_at_to"
              >
                <b-input-group>
                  <cleave
                    id="created_at_to"
                    v-model="runtimeFilters.created_at_to"
                    type="text"
                    class="form-control"
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                    :raw="false"
                    show-decade-nav
                    :options="maskDate"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="created_at_to_dp"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                      locale="pt-BR"
                      aria-controls="example-input"
                      @context="(ctx) => { runtimeFilters.created_at_to = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                    />
                  </b-input-group-append>

                  <b-input-group-append v-if="runtimeFilters.created_at_to.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="runtimeFilters.created_at_to = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              <span class="label-date text-right">Pagamento</span>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="De"
                label-for="paid_at_from"
              >
                <b-input-group>
                  <cleave
                    id="paid_at_from"
                    v-model="runtimeFilters.paid_at_from"
                    type="text"
                    class="form-control"
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                    show-decade-nav
                    :options="maskDate"
                    :raw="false"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="paid_at_from_dp"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                      locale="pt-BR"
                      aria-controls="example-input"
                      @context="(ctx) => { runtimeFilters.paid_at_from = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                    />
                  </b-input-group-append>

                  <b-input-group-append v-if="runtimeFilters.paid_at_from.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="runtimeFilters.paid_at_from = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Até"
                label-for="paid_at_to"
              >
                <b-input-group>
                  <cleave
                    id="paid_at_to"
                    v-model="runtimeFilters.paid_at_to"
                    type="text"
                    class="form-control"
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                    :raw="false"
                    show-decade-nav
                    :options="maskDate"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="paid_at_to_dp"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                      locale="pt-BR"
                      aria-controls="example-input"
                      @context="(ctx) => { runtimeFilters.paid_at_to = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                    />
                  </b-input-group-append>

                  <b-input-group-append v-if="runtimeFilters.paid_at_to.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="runtimeFilters.paid_at_to = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getFinancials()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          ref="cardResults"
          :title="filterByUser ? 'Financeiro' : 'Resultados'"
          class="form-filters"
          :action-close="false"
          :action-refresh="filterByUser > 0"
          :action-collapse="!filterByUser"
          @refresh="getFinancials()"
        >
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col
              v-if="filterByUser"
              cols="12"
              class="text-right"
            >
              <b-button
                class="mr-1"
                variant="primary"
                title="Serviços Adicionais"
                @click="addProduct()"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-middle"
                /> Serviços Adicionais
              </b-button>

              <b-button
                variant="primary"
                title="Gerar Link de Pagamento"
                @click="addPayment()"
              >
                <b-spinner
                  v-if="isOpening"
                  small
                />
                <feather-icon
                  v-else
                  icon="PlusIcon"
                  size="16"
                  class="align-middle"
                /> Gerar Link de Pagamento
              </b-button>
            </b-col>
          </b-row>

          <div
            v-if="filterById"
            style="margin-bottom: 20px"
          >
            <span>Mostrando somente a transação: {{ filterById }} - </span>
            <b-button
              size="sm"
              @click="removeFilterById()"
            >
              Mostrar todos pagamentos
            </b-button>
          </div>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                fixed
                :fields="result_fields"
                :items="results"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(transaction)>
                  <div class="text-nowrap">
                    Transação
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #head(account_manager)>
                  <div class="text-nowrap">
                    Gerente
                  </div>
                </template>

                <template #head(email)>
                  <div class="text-nowrap">
                    Usuário
                  </div>
                </template>

                <template #head(created_at)>
                  <div class="text-nowrap">
                    Criado em
                  </div>
                </template>

                <template #head(paid_at)>
                  <div class="text-nowrap">
                    Pago em
                  </div>
                </template>

                <template #cell(email)="row">
                  <label>Id: </label>{{ row.item.user ? row.item.user.id : '' }}<br>
                  <label>Email: </label>{{ row.item.user ? row.item.user.name : '' }}<br>
                  <label>Nome: </label>{{ row.item.user ? row.item.user.email : '' }}
                </template>

                <template #cell(transaction)="row">
                  <div v-if="row.item.id">
                    <label>Id:</label> {{ row.item.id }}<br>
                    <label>Valor:</label> {{ row.item.amount | currency }}<br>
                    <label>Status:</label> {{ $t(`financial.columns.status.${row.item.status}`) }}<br>
                    <label>Método:</label> {{ $t(`financial.columns.payment_type.${row.item.payment_type}`) }}<br>
                    <label>Plataforma:</label> {{ row.item.gateway_type }}<br>
                    <label>Recorrente:</label> {{ row.item.recurrent ? 'sim' : 'não' }} {{ row.item.recurrent_cutoff ? '*' : '' }}<br>
                  </div>
                  <div v-else>
                    Sem transação
                  </div>
                </template>

                <template #cell(created_at)="row">
                  <div
                    v-if="row.item.settings_success_manager_at"
                  >
                    {{ row.item.settings_success_manager_at | date }}
                  </div>
                  <div
                    v-else-if="row.item.created_at"
                  >
                    {{ row.item.created_at | date }}
                  </div>
                  <div
                    v-else-if="row.item.settings_account_manager_at"
                  >
                    {{ row.item.settings_account_manager_at | date }}
                  </div>
                </template>

                <template #cell(paid_at)="row">
                  <div>
                    {{ row.item.paid_at | date }}
                  </div>
                </template>

                <template #cell(account_manager)="row">
                  {{ row.item.account_manager ? row.item.account_manager.name : '' }}
                  <div v-if="row.item.settings_account_manager_at && !row.item.id">
                    Início em: {{ row.item.settings_account_manager_at | date }}
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('read', 'Users')"
                      variant="flat-primary"
                      class="btn-less-padding"
                      title="Abrir perfil"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.user_id } }"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      v-if="$can('update', 'Financial')"
                      variant="flat-primary"
                      class="btn-less-padding"
                      title="Ver html"
                      @click="openDetails(row)"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>

                <template
                  #row-details="row"
                >
                  <div class="details">
                    <p v-if="row.item.gateway_type === 'vindi'">
                      <span><strong>Tipo:</strong> Assinatura - </span>
                      <span v-if="row.item.isLoadingSubscription">Carregando status da assinatura</span>
                      <span v-if="!row.item.isLoadingSubscription">
                        <span v-if="!row.item.subscription">Assinatura não ativa</span>
                        <span v-if="row.item.subscription">Assinatura ativa ({{ row.item.subscription['id'] }}) <a
                          class="text-primary"
                          @click="cancelSubscription(row.item.user_id)"
                        >(Cancelar assinatura)</a></span>
                      </span>
                      <br>
                      <span><strong>TID:</strong> {{ row.item.gateway_ref }}</span>
                    </p>

                    <p v-if="row.item.invoice_pdf_link && row.item.invoice_pdf_link.length > 0">
                      <b-button
                        :href="row.item.invoice_pdf_link"
                        target="_blank"
                        variant="primary"
                      >
                        Baixar nota fiscal
                      </b-button>
                    </p>

                    <p v-if="row.item.gateway_type === 'iugu'">
                      <span v-if="row.item.gateway_raw && row.item.gateway_raw.subscription">
                        <span><strong>Tipo:</strong> Assinatura - </span>
                        <span v-if="row.item.isLoadingSubscription">Carregando status da assinatura</span>
                        <span v-if="!row.item.isLoadingSubscription">
                          <span v-if="!row.item.subscription">Assinatura não ativa</span>

                          <span v-if="row.item.subscription">Assinatura ativa ({{ row.item.subscription['id'] }}) <a
                            class="text-primary"
                            @click="cancelSubscription(row.item.user_id)"
                          >(Cancelar assinatura)</a></span>
                        </span>
                      </span>
                      <br>

                      <span v-if="row.item.gateway_raw && (row.item.gateway_raw.invoice || row.item.gateway_raw.charge)"><strong>Tipo:</strong> Pagamento</span>
                      <br>
                      <span><strong>Fatura ID:</strong> {{ row.item. gateway_ref }}</span>
                    </p>

                    <p v-if="row.item.products && row.item.products.length > 0">
                      <span><strong>Produtos: </strong> {{ row.item.products.map(m => (m.name)).join(',') }} </span>
                    </p>

                    <p v-if="row.item.plan">
                      <strong>Plano: </strong> {{ row.item.plan_group ? row.item.plan_group.name : '' }} - {{ row.item.plan.payment_interval_label }} ({{ row.item.plan.gateway_ref }})
                    </p>

                    <div class="d-flex justify-content-start">
                      <p
                        v-if="row.item.status === 'open'"
                        class="mr-2"
                      >
                        <b-button
                          size="sm"
                          @click="approvePayment(row)"
                        >
                          Aprovar pagamento
                        </b-button>
                      </p>

                      <p v-if="userData.permission_type === 'root'">
                        <b-button
                          size="sm"
                          @click="openModalNewManager(row.item.id)"
                        >
                          Alterar consultor
                        </b-button>
                      </p>
                    </div>

                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => '#'"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import { maskDate } from '@/utils/masks'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FinancialService from '@/services/financialService'
import UserService from '@/services/userService'
import {
  BFormDatepicker,
  BRow,
  BCol,
  BTable,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPaginationNav,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '../../../@core/components/toastification/ToastificationContent.vue'
import NewPayment from './NewPayment.vue'
import toast from '../../../mixins/toast'
import isMyUser from '../../../mixins/isMyUser'
import NewProduct from './NewProduct.vue'

export default {
  components: {
    NewPayment,
    NewProduct,
    BFormDatepicker,
    BCardActions,
    BRow,
    BCol,
    BTable,
    Cleave,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BPaginationNav,
    BSpinner,
    BButton,
  },

  mixins: [
    toast,
    isMyUser,
  ],

  props: {
    filterByUser: {
      type: Number,
      default: null,
    },
    filterById: {
      type: Number,
      default: null,
    },
    managerId: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
    recurrent: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isLoading: true,
      isOpening: false,
      canApprove: false,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Financeiro',
          active: true,
        },
      ],
      newManagerTransactionId: '',
      newManagers: [],
      newManagerId: '',
      isSubmitingNewManager: false,
      financialService: null,
      userService: null,
      userData: null,
      filtersInitialValues: {},
      created_at_from_dp: '',
      created_at_to_dp: '',
      paid_at_from_dp: '',
      paid_at_to_dp: '',
      user: null,
      showTransition: false,
      runtimeFilters: {
        created_at_from: '',
        created_at_to: '',
        paid_at_from: '',
        paid_at_to: '',
      },
      filtersValues: {
        name: '',
        status: 'all',
        payment_type: 'all',
        account_manager: 'all',
        recurrent: 'all',
        user_id: '',
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        recurrent: [
          { value: 'all', text: 'Todos' },
          { value: '0', text: 'Não' },
          { value: '1', text: 'Sim' },
        ],
        status: [
          { value: 'all', text: 'Todos' },
          { value: 'open', text: 'Aberto' },
          { value: 'paid', text: 'Pago' },
          { value: 'cancelled', text: 'Cancelado' },
          { value: 'error', text: 'Erro' },
        ],
        payment_type: [
          { value: 'all', text: 'Todos' },
          { value: 'credit_card', text: 'Cartão de crédito' },
          { value: 'debit_card', text: 'Cartão de débito' },
          { value: 'boleto', text: 'Boleto' },
          { value: 'pix', text: 'Pix' },
          { value: 'other', text: 'Outro' },
        ],
        account_manager: [],
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      maskDate,
    }
  },

  watch: {
    currentPage() {
      this.getFinancials()
    },

    filterById() {
      this.getFinancials()
    },
  },

  async created() {
    if (this.paymentDateFrom) {
      this.runtimeFilters.paid_at_from = this.paymentDateFrom
      this.paid_at_from_dp = this.paymentDateFrom.split('/').reverse().join('-')
    }

    if (this.recurrent) {
      this.filtersValues.recurrent = this.recurrent
    }

    if (this.paymentDateTo) {
      this.runtimeFilters.paid_at_to = this.paymentDateTo
      this.paid_at_to_dp = this.paymentDateTo.split('/').reverse().join('-')
    }

    if (this.managerId) {
      this.filtersValues.account_manager = this.managerId
    }

    this.financialService = new FinancialService()
    this.userService = new UserService()

    this.userData = JSON.parse(localStorage.getItem('userData'))
    await this.getManagers()
    this.getFinancials()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'transaction', sortable: false },
      { key: 'email', sortable: false },
      { key: 'account_manager', sortable: false },
      { key: 'created_at', sortable: true, thStyle: 'width: 110px' },
      { key: 'paid_at', sortable: true, thStyle: 'width: 110px' },
      { key: 'actions', sortable: true, thStyle: 'width: 100px' },
    ]
  },

  methods: {
    setNewManager() {
      this.isSubmitingNewManager = true

      this.financialService.changeManager(this.newManagerTransactionId, this.newManagerId).then(response => {
        if (response.status === 200) {
          this.getFinancials()
          this.$refs['new-manager-modal'].hide()
        } else {
          this.toastDanger('Ops', 'Algum erro aconteceu ao atribuir o novo consultor a este pagamento!')
        }
      }).catch(() => {
        this.toastDanger('Ops', 'Algum erro aconteceu ao atribuir o novo consultor a este pagamento!')
      }).finally(() => {
        this.isSubmitingNewManager = false
        this.newManagerId = ''
        this.newManagerTransactionId = ''
      })
    },

    openModalNewManager(transactionId) {
      this.newManagerTransactionId = transactionId
      this.$refs['new-manager-modal'].show()
    },

    approvePayment(row) {
      this.$swal({
        title: 'Aprovar pagamento?',
        text: 'Ao aprovar o pagamento, o Plano+Produtos vinculados ao pagamento serão imediatamente liberados para o associado.\n\nEsta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, aprovar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.financialService.approvePayment(row.item.id).then(response => {
            this.isLoading = false
            if (response.status === 200) {
              this.getFinancials()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Pagamento aprovado!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: error.response.data.errors.length > 1 ? `${error.response.data.errors[0]} ${error.response.data.errors[1]}` : error.response.data.errors,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },

    addProduct() {
      this.$refs['new-product-modal'].show()
    },

    addPayment() {
      this.isOpening = true
      if (this.filterByUser) {
        this.userService.getAllInformations(this.filterByUser).then(response => {
          this.user = response.data.data.user

          if (this.canSendPaymentLink(this.user, this.userData)) {
            this.$refs['payment-modal'].show()
          } else {
            this.getRuleReport().forEach(item => {
              // eslint-disable-next-line no-console
              console.log(item)
            })
            this.toastDanger('Ops', 'Você não pode gerar link de pagamento para este usuário pois ele pertence a outro consultor')
          }

          this.isOpening = false
        })
      } else {
        this.isOpening = false
        this.$refs['payment-modal'].show()
      }
    },

    openDetails(row) {
      row.toggleDetails()
      if (!row.detailsShowing) {
        this.checkSubscription(row.item)
      }
    },

    checkSubscription(item) {
      if ((item.gateway_raw && item.gateway_raw.subscription) || item.gateway_type === 'vindi') {
        const result = this.results.find(f => (f.id === item.id))
        result.isLoadingSubscription = true

        this.financialService.checkSubscription(item.user_id).then(response => {
          if (response.data.data.subscription) {
            result.subscription = response.data.data.subscription
          }
          result.isLoadingSubscription = false
        })
      }
    },

    cancelSubscription(userId) {
      this.$swal({
        title: 'Cancelar assinatura?',
        text: 'A assinatura será cancelada no gateway de pagamento, esta ação é irreversível',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.financialService.cancelSubscription(userId).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Assinatura cancelada!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          }).catch(response => {
            if (!response.response.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ops!',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: response.response.data.errors.join('\n'),
                },
              })
            }
          })
        }
      })
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.currentPage = 1
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getFinancials()
      }
    },

    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },

    transformFilterDate(dateFrom, dateTo) {
      if (dateTo.length === 10 && dateFrom.length === 10) {
        return `${this.transformDate(dateFrom)}::${this.transformDate(dateTo)}`
      }

      if (dateFrom.length === 10) {
        return this.transformDate(dateFrom)
      }

      return ''
    },

    transformFilters() {
      const ret = {
        ...this.filtersValues,
        created_at: this.transformFilterDate(this.runtimeFilters.created_at_from, this.runtimeFilters.created_at_to),
        paid_at: this.transformFilterDate(this.runtimeFilters.paid_at_from, this.runtimeFilters.paid_at_to),
      }

      if (this.filterByUser) {
        ret.user_id = this.filterByUser
      }

      if (this.filterById) {
        ret.id = this.filterById
      }

      return ret
    },

    removeFilterById() {
      this.$emit('update:filter-by-id', null)
    },

    async getManagers() {
      return this.userService.getAndStoreManagers().then(managers => {
        this.managers = managers

        this.newManagers = managers
          .filter(f => (f.permission_type === 'sales'))
          .map(m => ({ value: m.id, text: m.name }))

        this.newManagers.push({ value: 0, text: 'Remover consultor' })

        this.filtersOptions.account_manager = [
          { value: 'all', text: 'Todos' },
          ...this.managers.reduce((c, r) => [
            ...c,
            ...r.deleted_at ? [] : [
              { value: r.id, text: r.name },
            ],
          ], []).sort((a, b) => {
            if (a.text > b.text) {
              return 1
            }

            return (b.text > a.text) ? -1 : 0
          }),
        ]
      })
    },

    closeNewProduct() {
      this.$refs['new-product-modal'].hide()
    },

    closeFinancials() {
      this.$refs['payment-modal'].hide()
    },

    getFinancials() {
      this.isLoading = true

      this.financialService.list(this.transformFilters(), this.currentPage).then(response => {
        if (response.data !== null) {
          this.results = response.data.data.transactions.data.map(m => ({
            ...m,
            isLoadingSubscription: false,
          }))
          this.pages = { ...response.data.data.transactions, data: null }
          this.showTransition = this.results.filter(f => f.recurrent_cutoff !== '').length
        }
        this.isLoading = false
        this.$refs.cardResults.showLoading = false
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .financial {
    .btn-less-padding {
      padding: 0.35em 0.70em;
    }

    .header-card {
      margin-bottom: 20px;
    }

    .b-table {
      td {
        padding: 0.5rem 1rem;
      }
    }

    .label-date {
      display: block;
      margin-top: 30px;
      font-weight: bold;
    }

    .form-buttons button {
      margin-right: 10px;
    }
  }
</style>
